import * as React from 'react'

function SvgCash(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>,
) {
  return (
    <svg
      id="Cash_svg__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 216 138"
      ref={svgRef}
      {...props}
    >
      <defs>
        <clipPath id="Cash_svg__clip-path">
          <path
            d="M183.36-294.08H151a6.9 6.9 0 01-7-6.8v-20.75a6.89 6.89 0 016.88-6.9h32.46a6.88 6.88 0 017 6.8v20.79a6.89 6.89 0 01-6.92 6.86"
            fill="none"
            clipRule="evenodd"
          />
        </clipPath>
        <style>{'.Cash_svg__cls-3{fill:#1c1c1a}'}</style>
      </defs>
      <path
        className="Cash_svg__cls-3"
        d="M216 15.23a27.46 27.46 0 00-.36-4.05 13.57 13.57 0 00-1.27-3.86A13.09 13.09 0 00212 4a12.69 12.69 0 00-3.29-2.38A13.47 13.47 0 00204.8.39a27.59 27.59 0 00-4.06-.39H15.27a27.73 27.73 0 00-4.07.39 13.57 13.57 0 00-3.86 1.27A13 13 0 004.05 4a12.81 12.81 0 00-2.38 3.32 13.91 13.91 0 00-1.28 3.86A27.46 27.46 0 000 15.23v107.54a27.58 27.58 0 00.36 4 13.74 13.74 0 001.28 3.85A12.69 12.69 0 004.05 134a13.29 13.29 0 003.29 2.38 13.57 13.57 0 003.86 1.27 27.73 27.73 0 004.07.36H200.74a27.59 27.59 0 004.06-.36 13.47 13.47 0 003.86-1.27 12.9 12.9 0 005.68-5.67 13.4 13.4 0 001.27-3.85 27.58 27.58 0 00.36-4V17.09q.03-.93.03-1.86zm-4.58 105.66v1.75a22.13 22.13 0 01-.29 3.37 8.6 8.6 0 01-.84 2.58 8.17 8.17 0 01-1.54 2.11 8.06 8.06 0 01-2.12 1.54 8.82 8.82 0 01-2.57.84 23.36 23.36 0 01-3.36.29H15.39a23.14 23.14 0 01-3.37-.29 9 9 0 01-2.59-.84 8.42 8.42 0 01-3.66-3.65 9.27 9.27 0 01-.84-2.59 23.47 23.47 0 01-.29-3.36V17.12v-1.77A23.6 23.6 0 014.93 12a9.09 9.09 0 01.84-2.58 8.28 8.28 0 013.66-3.66A9.27 9.27 0 0112 4.91a23.67 23.67 0 013.37-.28h185.25a23.67 23.67 0 013.37.28 9.27 9.27 0 012.59.85 8.14 8.14 0 012.11 1.53 8.44 8.44 0 011.55 2.12 9.16 9.16 0 01.84 2.59 23.18 23.18 0 01.28 3.36v1.77z"
      />
      <path
        d="M211.39 17.13v105.51a22.13 22.13 0 01-.29 3.37 8.6 8.6 0 01-.84 2.58 8.17 8.17 0 01-1.54 2.11 8.06 8.06 0 01-2.12 1.54 8.82 8.82 0 01-2.57.84 23.36 23.36 0 01-3.36.29H15.39a23.14 23.14 0 01-3.37-.29 9 9 0 01-2.59-.84 8.42 8.42 0 01-3.66-3.65 9.27 9.27 0 01-.84-2.59 23.47 23.47 0 01-.29-3.36V17.12v-1.77A23.6 23.6 0 014.93 12a9.09 9.09 0 01.84-2.58 8.28 8.28 0 013.66-3.66A9.27 9.27 0 0112 4.91a23.67 23.67 0 013.37-.28h185.25a23.67 23.67 0 013.37.28 9.27 9.27 0 012.59.85 8.14 8.14 0 012.11 1.53 8.44 8.44 0 011.55 2.12 9.16 9.16 0 01.84 2.59 23.18 23.18 0 01.28 3.36q.03.87.03 1.77z"
        fill="#fff"
      />
      <path
        className="Cash_svg__cls-3"
        d="M216 15.23a27.46 27.46 0 00-.36-4.05 13.57 13.57 0 00-1.27-3.86A13.09 13.09 0 00212 4a12.69 12.69 0 00-3.29-2.38A13.47 13.47 0 00204.8.39a27.59 27.59 0 00-4.06-.39H15.27a27.73 27.73 0 00-4.07.39 13.57 13.57 0 00-3.86 1.27A13 13 0 004.05 4a12.81 12.81 0 00-2.38 3.32 13.91 13.91 0 00-1.28 3.86A27.46 27.46 0 000 15.23v107.54a27.58 27.58 0 00.36 4 13.74 13.74 0 001.28 3.85A12.69 12.69 0 004.05 134a13.29 13.29 0 003.29 2.38 13.57 13.57 0 003.86 1.27 27.73 27.73 0 004.07.36H200.74a27.59 27.59 0 004.06-.36 13.47 13.47 0 003.86-1.27 12.9 12.9 0 005.68-5.67 13.4 13.4 0 001.27-3.85 27.58 27.58 0 00.36-4V17.09q.03-.93.03-1.86zm-5.08 105.66v1.74a22.14 22.14 0 01-.28 3.29 8 8 0 01-.8 2.44 7.94 7.94 0 01-3.44 3.44 8.56 8.56 0 01-2.43.79 22.27 22.27 0 01-3.3.28H15.41a22.16 22.16 0 01-3.3-.28 8.2 8.2 0 01-2.45-.8 7.79 7.79 0 01-2-1.44 8 8 0 01-1.44-2 8.81 8.81 0 01-.8-2.45 23.69 23.69 0 01-.28-3.28v-.17V17.12v-1.76a24.08 24.08 0 01.28-3.28 9 9 0 01.79-2.44 8.33 8.33 0 011.45-2 7.89 7.89 0 012-1.44 8.61 8.61 0 012.45-.81 24.08 24.08 0 013.3-.27H200.6a24.08 24.08 0 013.3.27 8.78 8.78 0 012.46.81 7.79 7.79 0 012 1.43 8 8 0 011.45 2 8.6 8.6 0 01.8 2.45 23.4 23.4 0 01.27 3.29v1.75z"
      />
      <path d="M164.25 39.52h-8.5v-9.39a2.53 2.53 0 00-2.59-2.59H51.76a2.54 2.54 0 00-2.6 2.59v65.75a2.54 2.54 0 002.6 2.59h8.49v9.39a2.54 2.54 0 002.59 2.6h101.41a2.53 2.53 0 002.59-2.59V42.11a2.56 2.56 0 00-2.59-2.59zm-104 2.59v51.17h-5.9V32.73h96.2v6.79H62.84a2.54 2.54 0 00-2.59 2.59zm101.4 52.78v10.38h-10.57a13 13 0 013.49-6.7 13.14 13.14 0 017.08-3.68zm-96.21-.08A12.95 12.95 0 0176 105.27H65.44zm0-39.65V44.71h10.45a13.13 13.13 0 01-3.65 6.94 13 13 0 01-6.8 3.51zm0 5.24a19.43 19.43 0 0010.5-5.2 18.5 18.5 0 005.2-10.49h64.68A19.37 19.37 0 00151 55.2a18.45 18.45 0 0010.62 5.22v29.22A18.4 18.4 0 00151 94.89a19.33 19.33 0 00-5.18 10.38H81.27a19.35 19.35 0 00-5.19-10.49 18.4 18.4 0 00-10.64-5.21zm96.2-5.22a12.92 12.92 0 01-10.58-10.47h10.58z" />
      <path d="M113.48 93.56a18.64 18.64 0 10-18.64-18.64 18.66 18.66 0 0018.64 18.64zm0-5.3a13.34 13.34 0 1113.34-13.34 13.35 13.35 0 01-13.34 13.34z" />
    </svg>
  )
}

const ForwardRef = React.forwardRef(SvgCash)
export default ForwardRef
