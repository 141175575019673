import * as React from 'react'

function SvgAmex(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>,
) {
  return (
    <svg
      id="Amex_svg__Layer_1"
      data-name="Layer 1"
      viewBox="0 0 216 138"
      ref={svgRef}
      {...props}
    >
      <defs>
        <clipPath id="Amex_svg__clip-path">
          <path
            d="M426.92 58.78h-32.37a6.89 6.89 0 01-7-6.8V31.23a6.89 6.89 0 016.88-6.9h32.46a6.88 6.88 0 017 6.8v20.79a6.89 6.89 0 01-6.89 6.86h-.05"
            fill="none"
            clipRule="evenodd"
          />
        </clipPath>
        <style>{'.Amex_svg__cls-4{fill:#fff}'}</style>
      </defs>
      <path
        d="M216 19.3v103.47a26.26 26.26 0 01-.35 4 13.74 13.74 0 01-1.28 3.85A12.69 12.69 0 01212 134a13.29 13.29 0 01-3.29 2.38 13.57 13.57 0 01-3.86 1.27 27.71 27.71 0 01-4.06.36H15.26a27.47 27.47 0 01-4.06-.36 13.47 13.47 0 01-3.86-1.27A12.93 12.93 0 014.05 134a12.72 12.72 0 01-2.39-3.29 13.4 13.4 0 01-1.27-3.85 25.09 25.09 0 01-.36-4V17.09v-1.86a25.09 25.09 0 01.36-4.05 13.57 13.57 0 011.27-3.86A12.85 12.85 0 014.05 4a12.69 12.69 0 013.29-2.34A13.47 13.47 0 0111.2.39 27.47 27.47 0 0115.26 0H200.74a27.71 27.71 0 014.06.36 13.57 13.57 0 013.86 1.27A13 13 0 01212 4a12.81 12.81 0 012.38 3.28 13.91 13.91 0 011.28 3.86 26.26 26.26 0 01.35 4.05v1.86c-.01.78-.01 1.52-.01 2.25z"
        fill="#306fc5"
        fillRule="evenodd"
      />
      <g id="Amex_svg__logo">
        <path
          className="Amex_svg__cls-4"
          d="M14.83 48.75h7.73l-3.83-9.9-3.9 9.9zM56.14 81.95v4.58h13.04v4.95H56.14v5.32h14.47l6.67-7.42-6.3-7.43H56.14zM158.17 38.85l-4.21 9.9h8.1l-3.89-9.9zM93.92 99.58v-20.1l-9.22 9.9 9.22 10.2zM111.23 85.11a3.59 3.59 0 00-3.89-3.11h-7.42v6.37h7.79c2.1-.05 3.52-1.1 3.52-3.26zM135.9 87.58a2.82 2.82 0 001.1-2.47 2 2 0 00-1.06-2.47 6.47 6.47 0 00-2.84-.38h-7v5.63h7a5.77 5.77 0 002.8-.31z"
        />
        <path
          className="Amex_svg__cls-4"
          d="M186.8 29.64V34l-2.11-4.27h-16.57V34L166 29.7h-22.55a21.41 21.41 0 00-9.89 2.1v-2.1h-15.9v2.17a10.11 10.11 0 00-6.66-2.11H54.16l-3.9 8.85-3.89-8.85H28.31V34l-1.79-4.27H10.93l-7 16.58L0 55v9.67h14l.13-.37 2.06-5.3h4.58l2.1 5.63h20.47v-4.32l1.79 4.27h10.26l1.8-4.27v4.27h49.1v-9.15h.71c.68 0 .68 0 .68 1v7.79h25.41v-2.1a21.3 21.3 0 009.52 2.1h10.58l2.1-5.63h5l2.1 5.63h20.47V59l3.15 5.32h16.49V29.64zM67.08 59.32h-5.63V39.9l-.37.81-8.22 18.61h-5.2L39.19 39.9v19.42h-12L24.72 54h-12l-2.48 5.32H4l10.46-24.73h8.84l9.9 23.68V34.59h9.33l.19.37 4.45 9.71 3.22 7.23.12-.37 7.05-16.94h9.52zM91.44 39.9H77.66v4.58h13.41v5H77.66v4.94h13.78v5.32H71.66v-25h19.78zm25.17 9.16a1.76 1.76 0 01.37.43 6.57 6.57 0 011.24 4.14v5.75h-5.32v-2.84c0-1.42 0-3.53-1.05-4.95a2.75 2.75 0 00-1-.68c-.56-.37-1.55-.37-3.22-.37h-6.24v8.84h-6V34.65h13.42c3.15 0 5.32 0 7 1.05s2.78 2.78 2.84 5.5a6.92 6.92 0 01-4.2 6.5 4.91 4.91 0 012.16 1.36zm11.87 10.26h-6V34.59h6zm68.89 0h-7.79l-11.32-18.74v15.53l-.06-.11v3.22h-11.93l-2.11-5.32H151.8l-2.11 5.63H143c-2.85 0-6.37-.68-8.48-2.85s-3.15-4.94-3.15-9.52c0-3.52.68-7.05 3.15-9.89 1.8-2.11 5-2.85 8.85-2.85H149v5.32h-5.62a6.56 6.56 0 00-4.58 1.42 8.27 8.27 0 00-1.8 5.67c0 2.84.37 4.58 1.79 6a5.69 5.69 0 004.27 1.42h2.47l8.1-19.42h8.84l9.9 23.69V35h8.72l10.26 17.31V35h6z"
        />
        <path
          className="Amex_svg__cls-4"
          d="M111.6 44.92a1.19 1.19 0 00.25-.38 3.92 3.92 0 00.5-2.65c0-.13 0-.25-.07-.31v-.07a1.8 1.8 0 00-1-1.17 6.63 6.63 0 00-2.84-.37h-7v5.63h7a6.6 6.6 0 002.84-.38c.13-.06.19-.12.31-.18-.11-.04-.05-.04.01-.12zM199.41 71c-2.23 0-4.88 2.1-4.88 2.1V71h-16.27c-2.47 0-5.62.68-7 2.1V71h-28.99v2.1c-2.1-1.79-6-2.1-7.79-2.1h-19v2.1c-1.8-1.79-6-2.1-8.1-2.1H86.13l-4.95 5.32L76.6 71H44.82v35.68h31.1l5.14-5.14 4.45 5.14h19.85v-8.1h1.79c2.47 0 5.63 0 8.1-1v9.52h15.89v-9.19h.68c1.05 0 1.05 0 1.05 1v8.1h48c3.16 0 6.37-.68 8.1-2.1v2.1h15.21c3.16 0 6.37-.37 8.48-1.8a11.39 11.39 0 003.29-3V71zM107 92.53h-7v9.15H88.29L81.55 94l-.31-.37-7.79 8.16H50.88V77h22.94l6.25 6.86 1.3 1.42.18-.18L89 77h18.74c3.65 0 7.73.86 9.21 4.57a9.83 9.83 0 01.31 2.48c-.03 7.05-4.98 8.48-10.26 8.48zm36.36 9.15h-6v-3.16c0-1.42 0-3.89-1.05-4.94-.68-1-2.11-1-4.27-1h-6.37v9.21h-6v-25h13.42c2.84 0 5.32 0 7.05 1a6.17 6.17 0 013.15 5.62 7.1 7.1 0 01-4.29 6.71c1.85.68 2.9 1.36 3.27 2a8.17 8.17 0 011.06 4.57zM166.64 82h-13.79v4.58h13.42v5h-13.42v4.94h13.79v5.32h-19.79v-25h19.79zm14.84 19.79h-11.32v-5.37h11.32a3 3 0 002.47-.68 2.63 2.63 0 00.68-1.79 3.12 3.12 0 00-.68-1.79 2.87 2.87 0 00-2.1-.68c-5.63-.38-12.37 0-12.37-7.8 0-3.52 2.1-7.42 8.47-7.42h11.63v6h-11a5 5 0 00-2.47.37c-.68.37-.68 1.05-.68 1.8 0 1 .68 1.42 1.42 1.79a4.23 4.23 0 002.1.37h3.16c3.15 0 5.32.68 6.68 2.1a7.4 7.4 0 011.84 5.31c.06 5.27-3.15 7.74-9.15 7.74zm30.36-2.47c-1.42 1.42-3.9 2.47-7.42 2.47H193.1v-5.37h11.32a3 3 0 002.47-.68 2.63 2.63 0 00.68-1.79 3.12 3.12 0 00-.68-1.79 2.87 2.87 0 00-2.1-.68c-5.63-.38-12.37 0-12.37-7.8 0-3.34 1.92-6.36 6.68-7.29a16.92 16.92 0 011.79-.13h11.63v6h-11a5 5 0 00-2.47.37 2.64 2.64 0 00-.68 1.8c0 1 .37 1.42 1.42 1.79a4.23 4.23 0 002.1.37h3.22a11 11 0 013.77.56 7.63 7.63 0 014.95 5.56 7.9 7.9 0 01.12 1.24 7.25 7.25 0 01-2.11 5.32z"
        />
      </g>
    </svg>
  )
}

const ForwardRef = React.forwardRef(SvgAmex)
export default ForwardRef
