import * as React from 'react'

function SvgMastercard(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>,
) {
  return (
    <svg
      id="Mastercard_svg__Layer_1"
      data-name="Layer 1"
      viewBox="0 0 216 138"
      ref={svgRef}
      {...props}
    >
      <defs>
        <clipPath id="Mastercard_svg__clip-path">
          <path
            className="Mastercard_svg__cls-1"
            d="M426.92 231.84h-32.37a6.89 6.89 0 01-7-6.8v-20.75a6.9 6.9 0 016.88-6.9h32.46a6.87 6.87 0 017 6.79V225a6.89 6.89 0 01-6.92 6.86h-.05"
          />
        </clipPath>
        <clipPath id="Mastercard_svg__clip-path-2">
          <path
            className="Mastercard_svg__cls-1"
            d="M275.92 263.29h22.64c3.69 0 6.69 3.3 6.69 7.38s-3 7.38-6.69 7.38h-22.64c-3.7 0-6.69-3.3-6.69-7.38s3-7.38 6.69-7.38"
          />
        </clipPath>
        <clipPath id="Mastercard_svg__clip-path-3">
          <path
            className="Mastercard_svg__cls-1"
            d="M317.08 263.29h22.64c3.7 0 6.69 3.3 6.69 7.38s-3 7.38-6.69 7.38h-22.64c-3.69 0-6.69-3.3-6.69-7.38s3-7.38 6.69-7.38"
          />
        </clipPath>
        <clipPath id="Mastercard_svg__clip-path-4">
          <path
            className="Mastercard_svg__cls-1"
            d="M363.39 263.29H386c3.7 0 6.7 3.3 6.7 7.38s-3 7.38-6.7 7.38h-22.61c-3.69 0-6.69-3.3-6.69-7.38s3-7.38 6.69-7.38"
          />
        </clipPath>
        <clipPath id="Mastercard_svg__clip-path-5">
          <path
            className="Mastercard_svg__cls-1"
            d="M404.56 263.29h22.64c3.7 0 6.69 3.3 6.69 7.38s-3 7.38-6.69 7.38h-22.64c-3.69 0-6.69-3.3-6.69-7.38s3-7.38 6.69-7.38"
          />
        </clipPath>
        <style>
          {
            '.Mastercard_svg__cls-1{fill:none;clip-rule:evenodd}.Mastercard_svg__cls-6{fill:#f79e1b}'
          }
        </style>
      </defs>
      <path
        d="M19.34 137.75h-4.07a28.29 28.29 0 01-4-.35 13.51 13.51 0 01-3.79-1.25 12.53 12.53 0 01-3.22-2.34 12.81 12.81 0 01-2.35-3.22 13.22 13.22 0 01-1.24-3.78 25.7 25.7 0 01-.36-4V17.09v-1.85a25.7 25.7 0 01.36-4 13.22 13.22 0 011.21-3.8 12.82 12.82 0 015.57-5.56A13.19 13.19 0 0111.24.64a25.93 25.93 0 014-.36H200.73a25.93 25.93 0 014 .36 13.39 13.39 0 013.79 1.24 12.7 12.7 0 013.22 2.34 12.53 12.53 0 012.34 3.22 12.93 12.93 0 011.25 3.78 27 27 0 01.36 4V122.76a27 27 0 01-.36 4 12.93 12.93 0 01-1.25 3.78 12.53 12.53 0 01-2.34 3.22 12.7 12.7 0 01-3.22 2.34 13.51 13.51 0 01-3.79 1.25 28.41 28.41 0 01-4 .35H19.34z"
        fill="#eee"
        fillRule="evenodd"
      />
      <path
        d="M196.66.5H200.72a26.51 26.51 0 014 .35 13.23 13.23 0 013.72 1.23 12.26 12.26 0 013.16 2.32 12.4 12.4 0 012.29 3.15 13.34 13.34 0 011.23 3.72 27.51 27.51 0 01.35 4v107.49a27.5 27.5 0 01-.35 4 13.34 13.34 0 01-1.23 3.72 12.47 12.47 0 01-5.46 5.45 13.55 13.55 0 01-3.72 1.22 27.53 27.53 0 01-4 .35H15.28a27.54 27.54 0 01-4-.35 13.23 13.23 0 01-3.72-1.23 12.38 12.38 0 01-5.46-5.44 13.34 13.34 0 01-1.23-3.72 26.37 26.37 0 01-.35-4V17.1v-1.85a26.38 26.38 0 01.35-4 13.34 13.34 0 011.24-3.7A12.4 12.4 0 014.4 4.4a12.61 12.61 0 013.17-2.3A13.55 13.55 0 0111.29.88a26.4 26.4 0 014-.35h181.37m0-.5H17.13L15.26 0a27.71 27.71 0 00-4.06.39 13.47 13.47 0 00-3.86 1.27 12.9 12.9 0 00-5.68 5.67 13.4 13.4 0 00-1.27 3.85A27.58 27.58 0 000 15.23V122.77a27.58 27.58 0 00.36 4 13.57 13.57 0 001.27 3.86A13.09 13.09 0 004.05 134a12.69 12.69 0 003.29 2.38 13.47 13.47 0 003.86 1.27 27.71 27.71 0 004.06.36H200.74a27.71 27.71 0 004.06-.36 13.47 13.47 0 003.86-1.27A12.86 12.86 0 00212 134a13.09 13.09 0 002.39-3.28 13.57 13.57 0 001.27-3.86 27.58 27.58 0 00.36-4V121 19.31v-2.22-1.86a27.58 27.58 0 00-.36-4.05 13.4 13.4 0 00-1.27-3.85A13 13 0 00212 4a13.1 13.1 0 00-3.29-2.38A13.47 13.47 0 00204.8.39a27.71 27.71 0 00-4.06-.39h-1.87z"
        fill="#ccc"
      />
      <g id="Mastercard_svg__Layer_2" data-name="Layer 2">
        <g id="Mastercard_svg__Layer_1-2" data-name="Layer 1-2">
          <g id="Mastercard_svg___Group_" data-name="Group">
            <path fill="#ff5f00" d="M87.35 31.95h41.29v74.1H87.35z" />
            <path
              id="Mastercard_svg___Path_"
              data-name="Path"
              d="M90 69a47.06 47.06 0 0118-37 47.15 47.15 0 100 74.1A47 47 0 0190 69z"
              fill="#eb001b"
            />
            <path
              className="Mastercard_svg__cls-6"
              d="M184.35 69A47.2 47.2 0 01108 106a47.1 47.1 0 000-74 47.18 47.18 0 0176.35 37zM179.84 98.2v-1.52h.62v-.32h-1.56v.32h.67v1.52zm3 0v-1.84h-.47l-.55 1.31-.55-1.31h-.42v1.84h.34v-1.38l.51 1.19h.35l.51-1.19v1.39z"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

const ForwardRef = React.forwardRef(SvgMastercard)
export default ForwardRef
