import * as React from 'react'

function SvgChargeToMembership(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>,
) {
  return (
    <svg
      id="Charge-to-membership_svg__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 216 138"
      ref={svgRef}
      {...props}
    >
      <defs>
        <clipPath id="Charge-to-membership_svg__clip-path">
          <path
            d="M-52.64-294.08H-85a6.9 6.9 0 01-7-6.8v-20.75a6.89 6.89 0 016.88-6.9h32.46a6.88 6.88 0 017 6.8v20.79a6.89 6.89 0 01-6.92 6.86"
            fill="none"
            clipRule="evenodd"
          />
        </clipPath>
        <style>{'.Charge-to-membership_svg__cls-3{fill:#1c1c1a}'}</style>
      </defs>
      <path
        className="Charge-to-membership_svg__cls-3"
        d="M216 15.23a27.46 27.46 0 00-.36-4.05 13.57 13.57 0 00-1.27-3.86A13.09 13.09 0 00212 4a12.69 12.69 0 00-3.29-2.38A13.47 13.47 0 00204.8.39a27.59 27.59 0 00-4.06-.39H15.27a27.73 27.73 0 00-4.07.39 13.57 13.57 0 00-3.86 1.27A13 13 0 004.05 4a12.81 12.81 0 00-2.38 3.32 13.91 13.91 0 00-1.28 3.86A27.46 27.46 0 000 15.23v107.54a27.58 27.58 0 00.36 4 13.74 13.74 0 001.28 3.85A12.69 12.69 0 004.05 134a13.29 13.29 0 003.29 2.38 13.57 13.57 0 003.86 1.27 27.73 27.73 0 004.07.36H200.74a27.59 27.59 0 004.06-.36 13.47 13.47 0 003.86-1.27 12.9 12.9 0 005.68-5.67 13.4 13.4 0 001.27-3.85 27.58 27.58 0 00.36-4V17.09q.03-.93.03-1.86zm-4.58 105.66v1.75a22.13 22.13 0 01-.29 3.37 8.6 8.6 0 01-.84 2.58 8.17 8.17 0 01-1.54 2.11 8.06 8.06 0 01-2.12 1.54 8.82 8.82 0 01-2.57.84 23.36 23.36 0 01-3.36.29H15.39a23.14 23.14 0 01-3.37-.29 9 9 0 01-2.59-.84 8.42 8.42 0 01-3.66-3.65 9.27 9.27 0 01-.84-2.59 23.47 23.47 0 01-.29-3.36V17.12v-1.77A23.6 23.6 0 014.93 12a9.09 9.09 0 01.84-2.58 8.28 8.28 0 013.66-3.66A9.27 9.27 0 0112 4.91a23.67 23.67 0 013.37-.28h185.25a23.67 23.67 0 013.37.28 9.27 9.27 0 012.59.85 8.14 8.14 0 012.11 1.53 8.44 8.44 0 011.55 2.12 9.16 9.16 0 01.84 2.59 23.18 23.18 0 01.28 3.36v1.77z"
      />
      <path
        className="Charge-to-membership_svg__cls-3"
        d="M216 15.23a27.46 27.46 0 00-.36-4.05 13.57 13.57 0 00-1.27-3.86A13.09 13.09 0 00212 4a12.69 12.69 0 00-3.29-2.38A13.47 13.47 0 00204.8.39a27.59 27.59 0 00-4.06-.39H15.27a27.73 27.73 0 00-4.07.39 13.57 13.57 0 00-3.86 1.27A13 13 0 004.05 4a12.81 12.81 0 00-2.38 3.32 13.91 13.91 0 00-1.28 3.86A27.46 27.46 0 000 15.23v107.54a27.58 27.58 0 00.36 4 13.74 13.74 0 001.28 3.85A12.69 12.69 0 004.05 134a13.29 13.29 0 003.29 2.38 13.57 13.57 0 003.86 1.27 27.73 27.73 0 004.07.36H200.74a27.59 27.59 0 004.06-.36 13.47 13.47 0 003.86-1.27 12.9 12.9 0 005.68-5.67 13.4 13.4 0 001.27-3.85 27.58 27.58 0 00.36-4V17.09q.03-.93.03-1.86zm-5.08 105.66v1.74a22.14 22.14 0 01-.28 3.29 8 8 0 01-.8 2.44 7.94 7.94 0 01-3.44 3.44 8.56 8.56 0 01-2.43.79 22.27 22.27 0 01-3.3.28H15.41a22.16 22.16 0 01-3.3-.28 8.2 8.2 0 01-2.45-.8 7.79 7.79 0 01-2-1.44 8 8 0 01-1.44-2 8.81 8.81 0 01-.8-2.45 23.69 23.69 0 01-.28-3.28v-.17V17.12v-1.76a24.08 24.08 0 01.28-3.28 9 9 0 01.79-2.44 8.33 8.33 0 011.45-2 7.89 7.89 0 012-1.44 8.61 8.61 0 012.45-.81 24.08 24.08 0 013.3-.27H200.6a24.08 24.08 0 013.3.27 8.78 8.78 0 012.46.81 7.79 7.79 0 012 1.43 8 8 0 011.45 2 8.6 8.6 0 01.8 2.45 23.4 23.4 0 01.27 3.29v1.75z"
      />
      <path
        d="M210.89 17.13v105.5a22.14 22.14 0 01-.28 3.29 8 8 0 01-.8 2.44 7.94 7.94 0 01-3.44 3.44 8.56 8.56 0 01-2.43.79 22.27 22.27 0 01-3.3.28H15.41a22.16 22.16 0 01-3.3-.28 8.2 8.2 0 01-2.45-.8 7.79 7.79 0 01-2-1.44 8 8 0 01-1.44-2 8.81 8.81 0 01-.8-2.45 23.69 23.69 0 01-.28-3.28v-.17V17.12v-1.76a24.08 24.08 0 01.28-3.28 9 9 0 01.79-2.44 8.33 8.33 0 011.45-2 7.89 7.89 0 012-1.44 8.61 8.61 0 012.45-.81 24.08 24.08 0 013.3-.27H200.6a24.08 24.08 0 013.3.27 8.78 8.78 0 012.46.81 7.79 7.79 0 012 1.43 8 8 0 011.45 2 8.6 8.6 0 01.8 2.45 23.4 23.4 0 01.27 3.29c0 .63.01 1.17.01 1.76z"
        fill="#fff"
      />
      <path d="M58 72.92a32.58 32.58 0 00-32.54 32.54 5.08 5.08 0 005.07 5.07h55a5.07 5.07 0 005.06-5.07A32.58 32.58 0 0058 72.92zM31.94 104a26 26 0 0152 0zM58 70.26a19.72 19.72 0 10-19.77-19.72A19.74 19.74 0 0058 70.26zm0-6.49a13.23 13.23 0 1113.18-13.23A13.25 13.25 0 0158 63.77z" />
      <rect
        className="Charge-to-membership_svg__cls-3"
        x={104.58}
        y={44.64}
        width={84.05}
        height={7.27}
        rx={3.63}
      />
      <rect
        className="Charge-to-membership_svg__cls-3"
        x={104.58}
        y={67.04}
        width={84.05}
        height={7.27}
        rx={3.63}
      />
      <rect
        className="Charge-to-membership_svg__cls-3"
        x={104.58}
        y={89.44}
        width={50.17}
        height={7.27}
        rx={3.63}
      />
    </svg>
  )
}

const ForwardRef = React.forwardRef(SvgChargeToMembership)
export default ForwardRef
