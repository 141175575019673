import * as React from 'react'

function SvgCreditCard(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>,
) {
  return (
    <svg
      id="Credit-card_svg__Layer_1"
      data-name="Layer 1"
      viewBox="0 0 216 138"
      ref={svgRef}
      {...props}
    >
      <defs>
        <clipPath id="Credit-card_svg__clip-path">
          <path
            d="M183.36 58.78H151a6.9 6.9 0 01-7-6.8V31.23a6.89 6.89 0 016.88-6.9h32.46a6.88 6.88 0 017 6.8v20.79a6.89 6.89 0 01-6.92 6.86"
            fill="none"
            clipRule="evenodd"
          />
        </clipPath>
        <style>{'.Credit-card_svg__cls-5{fill:#fff}'}</style>
      </defs>
      <path
        d="M216 17.09v-1.86a27.58 27.58 0 00-.36-4.05 13.4 13.4 0 00-1.27-3.85A13 13 0 00212 4a13.1 13.1 0 00-3.29-2.38A13.47 13.47 0 00204.8.39a27.71 27.71 0 00-4.06-.39H15.26a27.71 27.71 0 00-4.06.39 13.47 13.47 0 00-3.86 1.27 12.9 12.9 0 00-5.68 5.67 13.4 13.4 0 00-1.27 3.85A27.58 27.58 0 000 15.23V122.77a27.58 27.58 0 00.36 4 13.57 13.57 0 001.27 3.86A13.09 13.09 0 004.05 134a12.69 12.69 0 003.29 2.38 13.47 13.47 0 003.86 1.27 27.71 27.71 0 004.06.36H200.74a27.71 27.71 0 004.06-.36 13.47 13.47 0 003.86-1.27A12.86 12.86 0 00212 134a13.09 13.09 0 002.39-3.28 13.57 13.57 0 001.27-3.86 27.58 27.58 0 00.36-4V121 19.31c-.02-.74-.02-1.48-.02-2.22z"
        fill="#c9c8cd"
      />

      <rect
        x={144.02}
        y={24.33}
        width={46.31}
        height={34.45}
        rx={7.88}
        fill="#ababb4"
      />
      <rect
        className="Credit-card_svg__cls-5"
        x={25.67}
        y={88.01}
        width={36.02}
        height={14.76}
        rx={7.38}
      />
      <rect
        className="Credit-card_svg__cls-5"
        x={68.55}
        y={88.01}
        width={36.02}
        height={14.76}
        rx={7.38}
      />
      <rect
        className="Credit-card_svg__cls-5"
        x={111.43}
        y={88.01}
        width={36.02}
        height={14.76}
        rx={7.38}
      />
      <rect
        className="Credit-card_svg__cls-5"
        x={154.31}
        y={88.01}
        width={36.02}
        height={14.76}
        rx={7.38}
      />
    </svg>
  )
}

const ForwardRef = React.forwardRef(SvgCreditCard)
export default ForwardRef
