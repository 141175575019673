import * as React from 'react'

function SvgApplePay(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>,
) {
  return (
    <svg data-name="Layer 1" viewBox="0 0 216 138" ref={svgRef} {...props}>
      <path d="M196.66 0H15.26a27.71 27.71 0 00-4.06.39 13.47 13.47 0 00-3.86 1.27A12.93 12.93 0 004.05 4a13.09 13.09 0 00-2.39 3.32 13.57 13.57 0 00-1.27 3.86A27.58 27.58 0 000 15.23V122.77a27.58 27.58 0 00.36 4 13.57 13.57 0 001.27 3.86A13.09 13.09 0 004.05 134a12.69 12.69 0 003.29 2.38 13.47 13.47 0 003.86 1.27 27.71 27.71 0 004.06.36H200.74a27.71 27.71 0 004.06-.36 13.47 13.47 0 003.86-1.27A12.86 12.86 0 00212 134a13.09 13.09 0 002.39-3.28 13.57 13.57 0 001.27-3.86 27.58 27.58 0 00.36-4V121 19.31v-2.22-1.86a27.58 27.58 0 00-.36-4.05 13.57 13.57 0 00-1.27-3.86 12.95 12.95 0 00-5.68-5.66A13.47 13.47 0 00204.8.39a27.71 27.71 0 00-4.06-.39h-1.87z" />
      <path
        d="M196.66 4.6h3.95a22.13 22.13 0 013.37.29 9 9 0 012.59.84 8.61 8.61 0 012.12 1.53 8.52 8.52 0 011.54 2.13 8.69 8.69 0 01.84 2.58 23.23 23.23 0 01.29 3.36v107.31a23.74 23.74 0 01-.29 3.38 8.92 8.92 0 01-.84 2.57 8.41 8.41 0 01-1.54 2.11 8.16 8.16 0 01-2.12 1.54 9 9 0 01-2.58.84 23.36 23.36 0 01-3.36.29H15.39a24.53 24.53 0 01-3.37-.28 9.45 9.45 0 01-2.6-.85 8.47 8.47 0 01-3.65-3.65 8.85 8.85 0 01-.84-2.59 22 22 0 01-.29-3.35V17.12v-1.76A22.35 22.35 0 014.93 12a8.89 8.89 0 01.84-2.58 8.4 8.4 0 011.54-2.13 8.25 8.25 0 012.12-1.53A8.89 8.89 0 0112 4.92a22.37 22.37 0 013.38-.29h181.27"
        fill="#fff"
      />
      <path d="M59 46.42a12 12 0 002.77-8.58 11.92 11.92 0 00-7.92 4.09A11.23 11.23 0 0051 50.18c3 .26 6.07-1.52 8-3.76M61.71 50.77c-4.42-.26-8.17 2.5-10.28 2.5S46.1 50.9 42.6 51a13 13 0 00-11.06 6.71c-4.74 8.16-1.25 20.26 3.36 26.9 2.24 3.29 4.94 6.91 8.49 6.78 3.36-.13 4.68-2.17 8.77-2.17s5.26 2.17 8.82 2.1S67 88 69.22 84.7a29 29 0 003.68-7.56 12 12 0 01-7.18-10.85c-.06-6.78 5.54-10 5.8-10.2a12.63 12.63 0 00-9.81-5.32M100.14 41.6c9.59 0 16.27 6.6 16.27 16.21s-6.82 16.27-16.52 16.27H89.27v16.86H81.6V41.6zM89.27 67.65h8.81c6.68 0 10.48-3.59 10.48-9.81s-3.8-9.78-10.45-9.78h-8.84zM118.41 80.72c0-6.3 4.83-10.16 13.4-10.64l9.87-.58v-2.77c0-4-2.71-6.39-7.23-6.39-4.29 0-7 2-7.61 5.26h-7c.41-6.49 6-11.28 14.87-11.28 8.74 0 14.32 4.62 14.32 11.83v24.79H142V85h-.17a12.89 12.89 0 01-11.38 6.53c-7.1.02-12.04-4.35-12.04-10.81zm23.27-3.25v-2.84l-8.88.55c-4.42.3-6.92 2.25-6.92 5.33s2.61 5.2 6.58 5.2c5.17 0 9.22-3.56 9.22-8.24zM155.74 104.17v-6a14.31 14.31 0 002.39.13c3.43 0 5.28-1.43 6.41-5.12 0-.07.65-2.19.65-2.23l-13-36h8l9.11 29.27h.14L178.55 55h7.82l-13.5 37.85c-3.09 8.72-6.65 11.52-14.12 11.52a29 29 0 01-3.01-.2z" />
    </svg>
  )
}

const ForwardRef = React.forwardRef(SvgApplePay)
export default ForwardRef
