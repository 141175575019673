import * as React from 'react'

function SvgVisa(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>,
) {
  return (
    <svg
      id="Visa_svg__Layer_1"
      data-name="Layer 1"
      viewBox="0 0 216 138"
      ref={svgRef}
      {...props}
    >
      <defs>
        <clipPath id="Visa_svg__clip-path">
          <path
            d="M111 58.92c-.09 7.79 7 12.13 12.25 14.72 5.45 2.65 7.28 4.35 7.26 6.72 0 3.63-4.35 5.24-8.38 5.3a29.24 29.24 0 01-14.37-3.42l-2.56 11.85c3.26 1.51 9.3 2.82 15.56 2.87 14.7 0 24.32-7.25 24.37-18.5.05-14.28-19.75-15.07-19.61-21.45 0-1.93 1.89-4 5.93-4.52a26.25 26.25 0 0113.8 2.42l2.46-11.47A37.43 37.43 0 00134.62 41c-13.84 0-23.57 7.35-23.65 17.88M171.35 42a6.4 6.4 0 00-6 4l-21 50.13h14.69L162 88h18l1.69 8.08h12.91L183.3 42h-12m2.05 14.62L177.64 77H166l7.37-20.31M93.17 42L81.59 96.12h14L107.16 42h-14m-20.7 0L57.9 78.84 52 47.54A6.52 6.52 0 0045.55 42H21.74l-.34 1.57c4.89 1.07 10.45 2.78 13.81 4.61 2.06 1.12 2.65 2.09 3.32 4.75L49.7 96.12h14.79L87.16 42h-14.7"
            fill="none"
          />
        </clipPath>
        <clipPath id="Visa_svg__clip-path-2">
          <path
            className="Visa_svg__cls-2"
            d="M183.36 231.84H151a6.9 6.9 0 01-7-6.8v-20.75a6.9 6.9 0 016.88-6.9h32.46a6.87 6.87 0 017 6.79V225a6.89 6.89 0 01-6.92 6.86"
          />
        </clipPath>
        <clipPath id="Visa_svg__clip-path-3">
          <path
            className="Visa_svg__cls-2"
            d="M32.36 263.29H55c3.69 0 6.69 3.3 6.69 7.38s-3 7.38-6.69 7.38H32.36c-3.7 0-6.69-3.3-6.69-7.38s3-7.38 6.69-7.38"
          />
        </clipPath>
        <clipPath id="Visa_svg__clip-path-4">
          <path
            className="Visa_svg__cls-2"
            d="M73.52 263.29h22.64c3.7 0 6.69 3.3 6.69 7.38s-3 7.38-6.69 7.38H73.52c-3.69 0-6.69-3.3-6.69-7.38s3-7.38 6.69-7.38"
          />
        </clipPath>
        <clipPath id="Visa_svg__clip-path-5">
          <path
            className="Visa_svg__cls-2"
            d="M119.84 263.29h22.64c3.69 0 6.69 3.3 6.69 7.38s-3 7.38-6.69 7.38h-22.64c-3.7 0-6.69-3.3-6.69-7.38s3-7.38 6.69-7.38"
          />
        </clipPath>
        <clipPath id="Visa_svg__clip-path-6">
          <path
            className="Visa_svg__cls-2"
            d="M161 263.29h22.64c3.7 0 6.69 3.3 6.69 7.38s-3 7.38-6.69 7.38H161c-3.69 0-6.69-3.3-6.69-7.38s3-7.38 6.69-7.38"
          />
        </clipPath>
        <linearGradient
          id="Visa_svg__linear-gradient"
          x1={-473.92}
          y1={140.89}
          x2={-473.57}
          y2={140.89}
          gradientTransform="scale(447.27 -447.27) rotate(20.218 158.81 1399.656)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#222357" />
          <stop offset={1} stopColor="#254aa5" />
        </linearGradient>
        <style>{'.Visa_svg__cls-2{fill:none;clip-rule:evenodd}'}</style>
      </defs>
      <path
        d="M19.34 137.75h-4.07a28.29 28.29 0 01-4-.35 13.51 13.51 0 01-3.79-1.25 12.53 12.53 0 01-3.22-2.34 12.81 12.81 0 01-2.35-3.22 13.22 13.22 0 01-1.24-3.78 25.7 25.7 0 01-.36-4V17.09v-1.85a25.7 25.7 0 01.36-4 13.22 13.22 0 011.21-3.8 12.82 12.82 0 015.57-5.56A13.19 13.19 0 0111.24.64a25.93 25.93 0 014-.36H200.73a25.93 25.93 0 014 .36 13.39 13.39 0 013.79 1.24 12.7 12.7 0 013.22 2.34 12.53 12.53 0 012.34 3.22 12.93 12.93 0 011.25 3.78 27 27 0 01.36 4V122.76a27 27 0 01-.36 4 12.93 12.93 0 01-1.25 3.78 12.53 12.53 0 01-2.34 3.22 12.7 12.7 0 01-3.22 2.34 13.51 13.51 0 01-3.79 1.25 28.41 28.41 0 01-4 .35H19.34z"
        fill="#eee"
        fillRule="evenodd"
      />
      <path
        d="M196.66.5H200.72a26.51 26.51 0 014 .35 13.23 13.23 0 013.72 1.23 12.26 12.26 0 013.16 2.32 12.4 12.4 0 012.29 3.15 13.34 13.34 0 011.23 3.72 27.51 27.51 0 01.35 4v107.49a27.5 27.5 0 01-.35 4 13.34 13.34 0 01-1.23 3.72 12.47 12.47 0 01-2.29 3.15 12.3 12.3 0 01-3.17 2.3 13.55 13.55 0 01-3.72 1.22 27.53 27.53 0 01-4 .35H15.28a27.54 27.54 0 01-4-.35 13.23 13.23 0 01-3.72-1.23 12.38 12.38 0 01-5.46-5.44 13.34 13.34 0 01-1.23-3.72 26.37 26.37 0 01-.35-4V17.1v-1.85a26.38 26.38 0 01.35-4 13.34 13.34 0 011.24-3.7A12.4 12.4 0 014.4 4.4a12.61 12.61 0 013.17-2.3A13.55 13.55 0 0111.29.88a26.4 26.4 0 014-.35h181.37m0-.5H17.13L15.26 0a27.71 27.71 0 00-4.06.39 13.47 13.47 0 00-3.86 1.27 12.9 12.9 0 00-5.68 5.67 13.4 13.4 0 00-1.27 3.85A27.58 27.58 0 000 15.23V122.77a27.58 27.58 0 00.36 4 13.57 13.57 0 001.27 3.86A13.09 13.09 0 004.05 134a12.69 12.69 0 003.29 2.38 13.47 13.47 0 003.86 1.27 27.71 27.71 0 004.06.36H200.74a27.71 27.71 0 004.06-.36 13.47 13.47 0 003.86-1.27A12.86 12.86 0 00212 134a13.09 13.09 0 002.39-3.28 13.57 13.57 0 001.27-3.86 27.58 27.58 0 00.36-4V121 19.31v-2.22-1.86a27.58 27.58 0 00-.36-4.05 13.4 13.4 0 00-1.27-3.85A13 13 0 00212 4a13.1 13.1 0 00-3.29-2.38A13.47 13.47 0 00204.8.39a27.71 27.71 0 00-4.06-.39h-1.87z"
        fill="#ccc"
      />
      <g id="Visa_svg__layer1">
        <g clipPath="url(#Visa_svg__clip-path)" id="Visa_svg__g10267">
          <g id="Visa_svg__g10269">
            <g id="Visa_svg__g10275">
              <path
                id="Visa_svg__path10283"
                d="M3.27 47.72l170.64-62.85 38.82 105.42-170.64 62.84"
                fill="url(#Visa_svg__linear-gradient)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

const ForwardRef = React.forwardRef(SvgVisa)
export default ForwardRef
