import * as React from 'react'

function SvgGooglePay(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>,
) {
  return (
    <svg
      id="GooglePay_svg__Layer_1"
      data-name="Layer 1"
      viewBox="0 0 216 138"
      ref={svgRef}
      {...props}
    >
      <defs>
        <style>
          {
            '.GooglePay_svg__cls-1,.GooglePay_svg__cls-3{fill:#3c4043}.GooglePay_svg__cls-1{fill-rule:evenodd}'
          }
        </style>
      </defs>
      <g id="GooglePay_svg__Page-1">
        <g
          id="GooglePay_svg__GooglePay_mark_800_gray"
          data-name="GooglePay mark 800 gray"
        >
          <g id="GooglePay_svg__border">
            <path
              id="GooglePay_svg__Fill-1"
              className="GooglePay_svg__cls-1"
              d="M196.66 0H15.26a27.71 27.71 0 00-4.06.39 13.47 13.47 0 00-3.86 1.27 12.9 12.9 0 00-5.68 5.67 13.4 13.4 0 00-1.27 3.85A27.58 27.58 0 000 15.23V122.77a27.58 27.58 0 00.36 4 13.57 13.57 0 001.27 3.86A13.09 13.09 0 004.05 134a12.69 12.69 0 003.29 2.38 13.47 13.47 0 003.86 1.27 27.71 27.71 0 004.06.36H200.74a27.71 27.71 0 004.06-.36 13.47 13.47 0 003.86-1.27A12.86 12.86 0 00212 134a13.09 13.09 0 002.39-3.28 13.57 13.57 0 001.27-3.86 27.58 27.58 0 00.36-4V121 19.31v-2.22-1.86a27.58 27.58 0 00-.36-4.05 13.4 13.4 0 00-1.27-3.85A13 13 0 00212 4a13.1 13.1 0 00-3.29-2.38A13.47 13.47 0 00204.8.39a27.71 27.71 0 00-4.06-.39h-4.08"
            />
            <path
              id="GooglePay_svg__wihit-fill"
              d="M196.66 4.6h3.95a22.13 22.13 0 013.37.29 9 9 0 012.59.84 8.36 8.36 0 012.12 1.53 8.52 8.52 0 011.54 2.13 8.69 8.69 0 01.84 2.58 23.23 23.23 0 01.29 3.36V122.65a23.73 23.73 0 01-.29 3.37 8.92 8.92 0 01-.84 2.57 8.41 8.41 0 01-1.54 2.11 8.16 8.16 0 01-2.12 1.54 9 9 0 01-2.58.84 22 22 0 01-3.36.29H15.39a23.05 23.05 0 01-3.37-.28 9.45 9.45 0 01-2.6-.85 8.17 8.17 0 01-2.11-1.54 8.41 8.41 0 01-1.54-2.11 8.85 8.85 0 01-.84-2.59 22 22 0 01-.29-3.35V17.12v-1.76A22.35 22.35 0 014.93 12a8.89 8.89 0 01.84-2.58A8.41 8.41 0 017.31 7.3a8.22 8.22 0 012.12-1.54A8.85 8.85 0 0112 4.92a22.13 22.13 0 013.37-.29h181.27"
              fill="#fffffe"
              fillRule="evenodd"
            />
          </g>
          <g id="GooglePay_svg__GPay-logo">
            <g id="GooglePay_svg__Pay">
              <path
                id="GooglePay_svg__Shape"
                className="GooglePay_svg__cls-3"
                d="M104 72.19v19.67h-6.16V43.3h16.32a14.58 14.58 0 0110.55 4.22 14.15 14.15 0 01.69 19.83l-.69.69a14.68 14.68 0 01-10.55 4.15zm0-22.92v16.95h10.32a8 8 0 006.08-2.51 8.62 8.62 0 00-.19-12 8.34 8.34 0 00-5.89-2.4z"
              />
              <path
                id="GooglePay_svg__Shape-2"
                data-name="Shape"
                className="GooglePay_svg__cls-3"
                d="M143.33 57.55c4.54 0 8.14 1.24 10.77 3.68s3.9 5.85 3.9 10.14v20.49h-5.89v-4.61h-.26c-2.56 3.79-5.93 5.69-10.17 5.69a13.1 13.1 0 01-9.06-3.25 10.33 10.33 0 01-3.62-8.13 10 10 0 013.86-8.21c2.56-2 6-3.06 10.28-3.06a18.41 18.41 0 019 2v-1.42a7.32 7.32 0 00-2.52-5.54 8.88 8.88 0 00-5.92-2.28 9.35 9.35 0 00-8.15 4.41L130.18 64c2.9-4.28 7.3-6.45 13.15-6.45zm-8 24.12a5.06 5.06 0 002 4.07 7.48 7.48 0 004.78 1.62 9.72 9.72 0 006.92-2.9 9.21 9.21 0 003.05-6.85c-1.91-1.55-4.58-2.33-8-2.29a10.54 10.54 0 00-6.27 1.82 5.45 5.45 0 00-2.44 4.53z"
              />
              <path
                id="GooglePay_svg__Path"
                className="GooglePay_svg__cls-1"
                d="M191.83 58.64l-20.56 47.81h-6.35l7.65-16.72-13.5-31.09h6.69l9.75 23.85h.11l9.52-23.85h6.69z"
              />
            </g>
            <g id="GooglePay_svg__Super-G" fillRule="evenodd">
              <path
                id="GooglePay_svg__Path-2"
                data-name="Path"
                d="M79.11 67.93a34.28 34.28 0 00-.46-5.65H52.7V73h14.87a12.94 12.94 0 01-5.51 8.48v7h8.87c5.2-4.87 8.18-12.03 8.18-20.55z"
                fill="#4285f4"
              />
              <path
                id="GooglePay_svg__Path-3"
                data-name="Path"
                d="M52.7 95.19c7.41 0 13.68-2.48 18.23-6.74l-8.87-7a16.57 16.57 0 01-24.8-8.83h-9.14v7.2A27.45 27.45 0 0052.7 95.19z"
                fill="#34a853"
              />
              <path
                id="GooglePay_svg__Path-4"
                data-name="Path"
                d="M37.26 72.65a17 17 0 010-10.68V54.8h-9.14a27.94 27.94 0 000 25z"
                fill="#fbbc04"
              />
              <path
                id="GooglePay_svg__Path-5"
                data-name="Path"
                d="M52.7 50.47a14.71 14.71 0 0110.55 4.18l7.87-8a26.38 26.38 0 00-18.42-7.22A27.48 27.48 0 0028.12 54.8l9.14 7.2A16.46 16.46 0 0152.7 50.47z"
                fill="#ea4335"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

const ForwardRef = React.forwardRef(SvgGooglePay)
export default ForwardRef
